<template>
    <div class="max-w-lg mx-auto p-4 bg-white mt-8">
        <div class="text-left">
            <label for="">Question</label>
            <div class="grid grid-cols-5 gap-4">
                <div class="col-span-full">
                    <div class="grid grid-cols-5 mb-2 mk"  v-for="(question, index) in form.questions" :key="index">
                        <Textarea
                            class="col-span-4 py-2 p-2 focus:outline-none border"
                            v-model="question.question"
                        />
                        <button class="col-span-1 text-red-500" @click="remove(index)">
                             &#9587;
                        </button>
                    </div>
                </div>
                <Btn type="button" @click="addQuestions()"> + Add</Btn>
            </div>
        </div>
        <div class="mt-2">
            <Btn class="" type="submit">Submit</Btn>
        </div>
         
    </div>
</template>
<script>
import Select from '../../../../../components/select.vue'
import Input from '../../../../../components/input-new.vue'
import Textarea from '../../../../../components/textarea.vue'
import Btn from '../../../../../components/btn.vue'
export default {
    components: {
        Select,
        Input,
        Textarea,
        Btn
    },
    data(){
        return {
            form:{
                questions: [
                    { question: "" }
                ],
            }
        }
    },
    methods:{
        addQuestions() {
            console.log('asche', this.form.questions);
            this.form.questions.push({
                question: "",
            });
        },
        
        remove(index){
            this.form.questions.splice(index, 1);
        }

    }
}
</script>